import * as React from "react";
import styles from "./cookies-component.module.scss";
import { ConsentManager } from "@segment/consent-manager";
import inEU from "@segment/in-eu";
import { useTranslation } from "next-i18next";

const preferencesDialogTitle = "";
const preferencesDialogContent = "";
const cancelDialogTitle = "";
const cancelDialogContent = "";
function CookiesComponent() {
  const { t } = useTranslation("common");

  function onHide() {
    const content = document.getElementById("__next");
    content.click();
  }

  const bannerContent = (
    <>
      <span>
        <img
          src="https://public.choose.app/website/new/images/Cookie.svg"
          alt="cookie"
          height="24"
        />
      </span>
      <span>
        {t("cookies")} (
        <a onClick={onHide} href="/privacy-policy">
          {t("cookies_parameters")}
        </a>
        )
      </span>
      <button onClick={onHide} className={styles.accept}>
        {t("cookies_accept")}
      </button>
    </>
  );
  return (
    <div id={styles.cookies}>
      <ConsentManager
        bannerSubContent={t("cookies")}
        preferencesDialogTitle={preferencesDialogTitle}
        preferencesDialogContent={preferencesDialogContent}
        cancelDialogTitle={cancelDialogTitle}
        cancelDialogContent={cancelDialogContent}
        implyConsentOnInteraction={true}
        bannerContent={bannerContent}
        writeKey={`${process.env.segment}`}
        shouldRequireConsent={inEU}
      />
    </div>
  );
}

export default CookiesComponent as any;
